import { AppBar } from "../navbar/Navbar";
import ExternalLinkGrid from "./ExternalLinkGrid/ExternalLinkGrid";

function Dashboard() {
  return (
    <>
      <AppBar />
      <ExternalLinkGrid />
    </>
  );
}

export default Dashboard;
