import ExternalLink from "../ExternalLink/ExternalLink";
import DescriptionBar from "../DescriptionBar/DescriptionBar";
import Microsoft365Admin from "../../../resources/microsoft365_admin.svg";
import Microsoft365Exchange from "../../../resources/microsoft365_exchange.svg";
import Wordpress from "../../../resources/wordpress.svg";
import Hostpoint from "../../../resources/hostpoint.svg";
import NasServer from "../../../resources/nas_server.svg";
import Bitdefender from "../../../resources/bitdefender.svg";
import Cloudflare from "../../../resources/cloudflare.svg";
import Hidora from "../../../resources/hidora.svg";
import Password from "../../../resources/1password.svg";
import Moodle from "../../../resources/moodle.svg";
import CMS from "../../../resources/cms.svg";
import Teampulse from "../../../resources/teampulse.svg";
import { Row } from "react-bootstrap";

function ExternalLinkGrid() {
  return (
    <>
      <DescriptionBar title="2assistU Anwendungen" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Wordpress}
          cardTitle="2assistU Website Wordpress Admin"
          cardTags="Website, Wordpress"
          link="https://www.2assistu.ch/2assistu/wp-login.php"
        />
        <ExternalLink
          imageSource={CMS}
          cardTitle="2assistU CMS"
          cardTags="Courses, Clients"
          link="https://www.2assistu.biz/cms/app/signin.php"
        />
      </Row>
      <DescriptionBar title="Microsoft Administration" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Microsoft365Admin}
          cardTitle="Microsoft 365 Admin Center"
          cardTags="Office, Mail, MS Teams, Azure AD, Licences"
          link="https://admin.exchange.microsoft.com/"
        />
        <ExternalLink
          imageSource={Microsoft365Exchange}
          cardTitle="Microsoft 365 Exchange Center"
          cardTags="Mailboxes, Groups"
          link="https://admin.microsoft.com/"
        />
      </Row>
      <DescriptionBar title="Hosting / Server Infrastruktur" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Hostpoint}
          cardTitle="Hostpoint Admin"
          cardTags="Domains, Web-Hosting, SSL, DNS"
          link="https://admin.hostpoint.ch/"
        />
        <ExternalLink
          imageSource={NasServer}
          cardTitle="2assistU NAS Datenserver Brugg"
          cardTags="Netzlaufwerke, NAS, Exchange"
          link="https://172.28.115.101:5001/"
        />
        <ExternalLink
          imageSource={NasServer}
          cardTitle="Infomaniak Backup NAS"
          cardTags="NAS, Netzlaufwerke, Backup"
          link="https://login.infomaniak.com/"
        />
        <ExternalLink
          imageSource={Cloudflare}
          cardTitle="Cloudflare"
          cardTags="DNS, Firewall, Domains"
          link="https://dash.cloudflare.com/"
        />
        <ExternalLink
          imageSource={Hidora}
          cardTitle="Hidora"
          cardTags="Moodle, teampulse, seduco"
          link="https://app.hidora.com/"
        />
      </Row>
      <DescriptionBar title="E-Learning" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Moodle}
          cardTitle="2assistU Moodle"
          cardTags="E-Learning, LMS, Production"
          link="https://moodle.2assistu.ch/"
        />
        <ExternalLink
          imageSource={Moodle}
          cardTitle="2assistU Test Moodle"
          cardTags="Testing, E-Learning, LMS"
          link="https://moodle-test.2assistu.ch/"
        />
        <ExternalLink
          imageSource={Wordpress}
          cardTitle="2assistU LMS Admin"
          cardTags="LMS, E-Learning, Wordpress"
          link="https://2assistu.biz/lms/wp-login.php"
        />
      </Row>
      <DescriptionBar title="teampulse" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Teampulse}
          cardTitle="teampulse Production Master"
          cardTags="Production, QAM, TRG, ORG, IOR, RSM, CRM, ADM"
          link="https://master.teampulse.co/login"
        />
        <ExternalLink
          imageSource={Teampulse}
          cardTitle="teampulse Test Master"
          cardTags="Testing, QAM, TRG, ORG, IOR, RSM, CRM, ADM"
          link="https://master.teampulse.it/login"
        />
      </Row>
      <DescriptionBar title="Diverse Tools" />
      <Row xs={1} md={6} className="justify-content-start ms-2">
        <ExternalLink
          imageSource={Bitdefender}
          cardTitle="Bitdefender Gravity Zone"
          cardTags="Firewall, Virenschutz, Netzwerküberwachung"
          link="https://gravityzone.bitdefender.com/"
        />
        <ExternalLink
          imageSource={Password}
          cardTitle="1Password Administration"
          cardTags="Password, Tresor"
          link="https://my.1password.com/"
        />
      </Row>
    </>
  );
}
export default ExternalLinkGrid;
