import { Card } from "react-bootstrap";
import "./ExternalLink.css";

function ExternalLink(props) {
  return (
    <Card bg="dark" className="m-4 ">
      <a className="link" href={props.link} target="_blank" rel="noreferrer">
        <Card.Img className="cardImage" variant="top" src={props.imageSource} />
        <Card.Body>
          <Card.Title className="cardTitle d-flex justify-content-center">
            {props.cardTitle}
          </Card.Title>
          <Card.Text className="cardText d-flex justify-content-center">
            {props.cardTags}
          </Card.Text>
        </Card.Body>
      </a>
    </Card>
  );
}

export default ExternalLink;
