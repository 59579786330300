import { Container, Row } from "react-bootstrap";
import { SignInButton } from "./SignInButton";
import Logo from "../../resources/2assistU-IT-Dashboard.svg";
import "./LoginPage.css";

function LoginPage() {
  return (
    <div className="loginPageBackground">
      <div className="loginPageForeground">
        <Container fluid>
          <Row md="auto" className="d-flex justify-content-center">
            <img className="logoLoginPage" alt="2assistU" src={Logo} />
          </Row>
          <Row md="auto" className="d-flex justify-content-center signInButton">
            <SignInButton />
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LoginPage;
