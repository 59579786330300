import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import Button from "react-bootstrap/Button";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="outline-light"
      className="ml-auto"
      onClick={() => handleLogin(instance)}
    >
      Sign in with Microsoft
    </Button>
  );
};
