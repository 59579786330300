import { Row } from "react-bootstrap";
import "./DescriptionBar.css";

function DescriptionBar(props) {
  return (
    <Row className="descriptionBar">
      <h4 className="descriptionBarTitle">{props.title}</h4>
      <hr className="descriptionBarLine" />
    </Row>
  );
}

export default DescriptionBar;
