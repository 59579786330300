import { Container, Col } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { SignOutButton } from "../auth/SignOutButton";
import Logo from "../../resources/2assistU-IT-Dashboard.svg";
import "./Navbar.css";

export const AppBar = () => {
  return (
    <>
      <Navbar bg="dark" sticky="top">
        <Container fluid>
          <Col md="auto">
            <img className="logo" alt="2assistU" src={Logo} />
          </Col>
          <Col className="d-flex justify-content-end">
            <SignOutButton />
          </Col>
        </Container>
      </Navbar>
    </>
  );
};
