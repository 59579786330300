export const msalConfig = {
  auth: {
    clientId: "6b0ea084-55ca-4aa4-aced-e9ea6d2b9681",
    authority:
      "https://login.microsoftonline.com/132fce80-1683-4ec2-bbcb-5b70e277385f",
    redirectUri: "https://dashboard.2assistu.ch",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};
